import * as React from "react"
import { Link } from "gatsby"
import Logo from '../icons/Logo.js'
import Button from '../components/Button.js'

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-xl navbar-dark bg-color-gray" role="navigation" aria-label="main navigation">
      
        <div className="container-fluid container-xg">
          <div className="navbar-brand">
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav align-self-end">
              <li className="nav-item d-flex align-items-center">
                <Button to="/projetos" className="navbar-item">
                  Projetos
                </Button>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Button to="/cursos" className="navbar-item">
                  Cursos 
                </Button>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Button to="/workshops" className="navbar-item">
                  Workshops
                </Button>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Button to="/cases" className="navbar-item">
                  Cases
                </Button>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Button to="/blog" className="navbar-item">
                  Blog
                </Button>
              </li>
              <li className="nav-item d-flex align-items-center">
                <Button to="/quem-somos" className="navbar-item">
                  Sobre
                </Button>
              </li>              
              {/* <li className="nav-item d-flex align-items-center dropdown">
                <Button to="#" className="navbar-item dropdown-toggle" href="#" id="main-menu_sobre" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Sobre
                </Button>
                <ul className="dropdown-menu" aria-labelledby="main-menu_sobre">
                  <li>
                    <Button to="/quem-somos" className="navbar-item dropdown-item">
                      Quem Somos
                    </Button>
                  </li>
                  <li>
                    <Button to="/na-midia" className="navbar-item dropdown-item">
                      Na Mídia
                    </Button>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item d-flex align-items-center">
                <Button to="/contato" className="navbar-item button">
                  Contato
                </Button>
              </li>
            </ul>
          </div>
        </div>
      
    </nav>
  )
}

export default Navbar
