import * as React from "react"
import Navbar from './elements/Navbar.js'
import HeaderNotification from './elements/HeaderNotification.js'

const CommonHeader = () => {
  
  return (
    <header>
      <HeaderNotification />
      <Navbar />
    </header>
  )
}

export default CommonHeader
