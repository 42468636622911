import * as React from "react"
import Copyright from './elements/Copyright.js'
import Logo from './icons/Logo.js'
import SocialMedia from "./elements/SocialMedia.js"
import Button from './components/Button.js'

const CommonFooter = () => {
  
  return (
    <footer className="bg-color-gray c-white">
      <div className="container-xg">
        <div className="container-fluid pt-3r pb-4r">
          <div className="row">
            <div className="col-12 col-sm-4 text-center text-md-left">
              <Logo className="img-fluid mb-6" />
            </div>
            <div className="col d-flex justify-content-center justify-content-sm-end align-items-sm-center">
              <ul className="quicky-menu">
                <li><Button to="/projetos" className="c-white">Projetos</Button></li>
                <li><Button to="/cursos" className="c-white">Cursos</Button></li>
                <li><Button to="/workshops" className="c-white">Workshops</Button></li>
                <li><Button to="/cases" className="c-white">Cases</Button></li>
                <li><Button to="/blog" className="c-white">Blog</Button></li>
                <li><Button to="/quem-somos" className="c-white">Sobre</Button></li>
                <li><Button to="/na-midia" className="c-white">Na Mídia</Button></li>
                <li><Button to="/contato" className="c-white">Contato</Button></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 text-center text-sm-start">
              <div className="fs-body-small opacity-50">
                <p className="mt-25r lh-14">
                  (21) 3942-8895
                </p>
                <p className="mt-15r lh-14">
                  Comunicação Integrada Cursos e Soluções <br />
                  CNPJ 25.122.101/0001-66
                </p>
              </div>
            </div>
            <div className="col order-1 order-md-2 d-flex align-items-center justify-content-center justify-content-sm-end">
              <SocialMedia />
            </div>
          </div>
        </div>        
      </div>
      <Copyright />
    </footer>
  )
}

export default CommonFooter
