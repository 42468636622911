import * as React from "react"
import Button from "../components/Button"

const SocialMedia = () => {  
  return (
    <ul className="social-media">
      {/* Youtube */}
      <li><Button to="https://www.youtube.com/channel/UCRqD4-WG6Ta4525cWtLfHmA" targetBlank>
        <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.3237 15.0492L13.3223 6.51859L21.7933 10.7988L13.3237 15.0492ZM31.9225 5.04077C31.9225 5.04077 31.6162 2.94856 30.6762 2.02723C29.484 0.817993 28.1478 0.81199 27.535 0.741212C23.1479 0.434082 16.5671 0.434082 16.5671 0.434082H16.5535C16.5535 0.434082 9.9728 0.434082 5.58559 0.741212C4.97276 0.81199 3.63697 0.817993 2.44439 2.02723C1.50441 2.94856 1.19853 5.04077 1.19853 5.04077C1.19853 5.04077 0.88501 7.4977 0.88501 9.95462V12.258C0.88501 14.7149 1.19853 17.1718 1.19853 17.1718C1.19853 17.1718 1.50441 19.264 2.44439 20.1853C3.63697 21.3946 5.2035 21.3563 5.90122 21.4831C8.40931 21.716 16.5603 21.7881 16.5603 21.7881C16.5603 21.7881 23.1479 21.7785 27.535 21.4714C28.1478 21.4006 29.484 21.3946 30.6762 20.1853C31.6162 19.264 31.9225 17.1718 31.9225 17.1718C31.9225 17.1718 32.2356 14.7149 32.2356 12.258V9.95462C32.2356 7.4977 31.9225 5.04077 31.9225 5.04077Z" fill="white"/>
        </svg>
      </Button></li>
      {/* LinkedIn */}
      <li><Button to="https://www.linkedin.com/company/comunica%C3%A7%C3%A3o-integrada---consultoria-e-planejamento" targetBlank>
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M21.539 20.646H17.8321V15.0278C17.8321 13.6874 17.8078 11.9637 15.905 11.9637C13.9752 11.9637 13.6814 13.4244 13.6814 14.9316V20.646H9.97794V9.09204H13.5321V10.6718H13.5828C14.0772 9.76322 15.2877 8.80554 17.0918 8.80554C20.8453 8.80554 21.539 11.1977 21.539 14.3095V20.646ZM5.7967 7.51362C4.60573 7.51362 3.64531 6.58083 3.64531 5.4308C3.64531 4.28146 4.60573 3.34866 5.7967 3.34866C6.9828 3.34866 7.946 4.28146 7.946 5.4308C7.946 6.58083 6.9828 7.51362 5.7967 7.51362ZM7.65155 20.646V9.09204H3.94045V20.646H7.65155ZM23.3856 0.015625H2.08003C1.06267 0.015625 0.235596 0.797099 0.235596 1.7615V22.48C0.235596 23.4438 1.06267 24.2266 2.08003 24.2266H23.3856C24.4057 24.2266 25.2349 23.4438 25.2349 22.48V1.7615C25.2349 0.797099 24.4057 0.015625 23.3856 0.015625Z" fill="white"/>
        </svg>
      </Button></li>
    </ul>
  )
}

export default SocialMedia


