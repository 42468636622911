import * as React from "react"
import PersonImage from "../components/PersonImage"
import LinkedIN from "../icons/LinkedIN.js"

import Button from '../components/Button.js'

export default function StaffDisplay({ imageSrc, linkedIn, nome, cargo}){

  return (
    <div className="col-md-6 col-lg-4 col-xl-3 my-6">

      <PersonImage
        imageSrc={imageSrc}
        linkedIn={linkedIn}
      />

      <div className="d-flex justify-content-center">
        <Button to={linkedIn} className="linkedin-btn" targetBlank="true">
          <LinkedIN width="32" height="32" fill="#C1B6D8" />
        </Button>
        <ul className="list-unstyled ps-3 mb-0 mw-240">
          <li className="h4 c-purple bold">{nome}</li>
          <li className="fs-body-default">{cargo}</li>
        </ul>
      </div>


    </div>
  )
}
