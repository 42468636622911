import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Seo from 'gatsby-plugin-wpgraphql-seo'


import ContainerWithBg from "../common/helpers/ContainerWithBg.js"
import Container from "../common/helpers/Container.js"
import QuemSomosVisao from '../common/icons/QuemSomosVisao'
import QuemSomosValores from '../common/icons/QuemSomosValores'
import Button from '../common/components/Button.js'


import Page from "../common/elements/Page.js"
import StaffDisplay from "../common/elements/StaffDisplay"

import EmpressaParceiras from "../common/section/EmpresasParceiras.js"
import FormContatoImageSection from "../common/section/FormContatoImageSection.js"


export default function SobrePage({ data }) {

  const quemSomos = data.wpPage.quemSomosFields

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/sobre-hero.png"></link>
      </Helmet>
      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-sobre-hero py-4 py-lg-0"
        className="container-md tac mh-27r ai-c jc-c dfc c-white p-r z1 feature-image"
      >
        <h1 className="h1-egg mb-4">Comunicação Integrada</h1>
        <p className="mb-0">Acreditamos que a Comunicação Integrada traz resultados efetivos nos negócios e fortalece a reputação</p>
      </ContainerWithBg>
      <ContainerWithBg className="container-xl" classBgName="bg-color-golden p-r" noDisplay>
        <div className="py-4 py-lg-8">
          <div className="col-size-720">
            <p className="h3 c-purple tac mb-0">
              {quemSomos.comunicacaoQuemSomosIntro}
            </p>
          </div>
        </div>
      </ContainerWithBg>
      <ContainerWithBg className="container-xl" classBgName="bg-color-purple-4 p-r" noDisplay>
        <div className="py-4 py-lg-8">
          <div className="col-size-720">
            <p className="h3 c-purple tac mb-0">
              Nossa missão é ajudar empresas de médio e grande porte (nacionais e multinacionais) a otimizarem seus resultados por meio de uma metodologia exclusiva que conecta o Diagnóstico 360° ao Mapeamento de Fluxos de Comunicação, Processos e Riscos, passando pelo Planejamento e Gestão Estratégica da Comunicação Integrada.
            </p>
          </div>
        </div>
      </ContainerWithBg>
      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 bg-color-purple-1 d-flex align-items-stretch justify-content-center">

            <div className=" p-4 p-lg-8 d-flex">
              <div className="col-size-424 d-flex flex-column justify-content-between">
                {/* Visão */}
                <div className="pb-8">
                  <QuemSomosVisao />
                </div>
                <div className="packing">
                  <h2 className="h3 c-white">Nossa Visão</h2>
                  <p className="c-white">Ser reconhecida como referência nas áreas de Planejamento de Comunicação Integrada (do Diagnóstico à Mensuração de Resultados), Mapeamento de Fluxos de Comunicação, Processos e Riscos e Gestão Estratégica de Projetos.</p>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6 bg-color-purple d-flex align-items-stretch justify-content-center">

            <div className="p-4 p-lg-8 d-flex">
              <div className="col-size-424 d-flex flex-column h-100">
                {/* Visão */}
                <div className="pb-8">
                  <QuemSomosValores />
                </div>
                <div className="packing">
                  <h2 className="h3 c-white">Nossos Valores</h2>
                  <ul className="list-default c-white">
                    <li>Excelência e inovação nas entregas;</li>
                    <li>Integração de processos, pessoas e estratégias;</li>
                    <li>Foco em resultados.</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Container>

      <ContainerWithBg className="container-xl" classBgName="bg-color-golden" noDisplay>
        <div className="py-4 py-lg-8">
          <div className="col-size-720">
            <h2 className="h2 c-purple tac mb-4">Por que existimos?</h2>
            <p className="h3 c-gray tac">
              <strong>Com expertise na realização de diagnóstico, planejamento de comunicação e criação de estratégias para mais de vinte empresas de médio e grande porte, a Comunicação Integrada tem o projeto certo para gerar resultados em seus negócios, além de cursos online e workshops in Company.</strong>
            </p>
          </div>
        </div>
      </ContainerWithBg>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 order-md-2 bg-color-purple-1 d-flex align-items-center">
            <div className="col-size-560 p-4 p-lg-8">

              <h4 className="mb-3 h4 c-golden ttu">O que fazemos?</h4>
              <div
                dangerouslySetInnerHTML={{ __html: quemSomos.comunicacaoQuemSomosFazemos }}
                className="c-white pb-6 h3 list-default"
              />

              <h4 className="mb-3 h4 c-golden ttu">Para quem?</h4>
              <div
                dangerouslySetInnerHTML={{ __html: quemSomos.comunicacaoQuemSomosQuem }}
                className="c-white pb-6 list-default"
              />

              <Button className="bttn outline dib h3 c-white" to="/consultoria">
                Conheça nossos Projetos
              </Button>

            </div>
          </div>
          <div className="col-md-6 order-md-1 d-flex p-0">
            <StaticImage src="../images/quem-somos-img.png" alt="Quem Somos" />
          </div>
        </div>
      </Container>

      <EmpressaParceiras logos={quemSomos.comunicacaoQuemSomosEmpresas} field="comunicacaoQuemSomosEmpresasLogo" />

      <ContainerWithBg classBgName="bg-color-purple-3 py-4 py-lg-8" className="container-xg">
        <div className="container-fluid">          
          <h3 className="h2 c-purple mb-4 s-t m-auto">Sobre Isabela Pimentel</h3>
          <div className="row justify-content-center">
            {
              data.allWpEquipe.nodes.map(node => (
                <StaffDisplay
                  imageSrc={node.equipeFields.comunicacaoEquipeFoto}
                  linkedIn={node.equipeFields.comunicacaoEquipeLinkedin}
                  nome={node.equipeFields.comunicacaoEquipeNome}
                  cargo={node.equipeFields.comunicacaoEquipeCargo}
                  key={node.id}
                />
              ))
            }
          </div>
        </div>
      </ContainerWithBg>

      <Container className="py-4 py-lg-8 sep-y bg-color-purple-4">
        <div className="container">
          <h2 className="h2 c-purple tac pb-3">Parceiros</h2>
          <div className="row justify-content-center">
            <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://www.aberje.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-aberje-parceiro.png"
                  alt="Aberje"
                />
              </Button>
            </div>
            {/* <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://www.abracom.org.br" targetBlank="true">
                <StaticImage 
                  src="../images/associado-abracom.png"
                  alt="Abracom"
                />
              </Button>
            </div> */}
            <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://www.espm.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-parceiro-espm.png"
                  alt="Espm"
                />
              </Button>
            </div>
            <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="http://www.amcham.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-parceiro-amcham.png"
                  alt="Amcham"
                />
              </Button>
            </div>
            <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://integramd.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-parceiro-integramd.png"
                  alt="Integra MD"
                />
              </Button>
            </div>
            <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://superacomunicacao.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-parceiro-supera.png"
                  alt="Supera"
                />
              </Button>
            </div>
            {/* <div className="col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-center py-2">
              <Button to="https://groupcaliber.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-parceiro-caliber.png"
                  alt="GroupCaliber Brasil"
                />
              </Button>
            </div> */}
            {/* <div className="col-6 col-md-4 col-lg-2 d-flex align-items-center justify-content-center py-2">
              <Button to="https://www.aberje.com.br/" targetBlank="true">
                <StaticImage 
                  src="../images/logo-media-aberj.png"
                  alt="Aberje"
                />
              </Button>
            </div> */}

          </div>
        </div>

      </Container>

      <Container className="container-xl py-4 py-lg-8">
        
        <h2 className="h2 c-purple tac pb-3">Nossa História</h2>

        <ul className="timeline">
          {quemSomos && quemSomos.comunicacaoQuemSomosTimeline &&
            quemSomos.comunicacaoQuemSomosTimeline.map((node, index) => (
              <li className="item">
                <div key={`timeline-${index}`} className="timeline-card">
                  <time>{node.comunicacaoQuemSomosTimelineAno}</time>
                  {node.comunicacaoQuemSomosTimelineImagem &&
                    <GatsbyImage
                      image={getImage(node.comunicacaoQuemSomosTimelineImagem.localFile)}
                      alt={node.comunicacaoQuemSomosTimelineImagem.altText}
                      objectFit="cover"
                      className="roundr mb-3"
                      imgClassName=""
                    />
                  }
                  <h3 className="h3 c-purple-1">{node.comunicacaoQuemSomosTimelineTitulo}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: node.comunicacaoQuemSomosTimelineDescricao }}
                    className="the-content"
                  />
                </div>
              </li>

            ))
          }
        </ul>
      </Container>
      <FormContatoImageSection className="sep" />

    </Page >
  )
}

export const pageQuery = graphql`
  {
    wpPage(databaseId: {eq: 20967}) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      quemSomosFields {
        comunicacaoQuemSomosQuem
        comunicacaoQuemSomosFazemos
        comunicacaoQuemSomosIntro
        comunicacaoQuemSomosTimeline {
          comunicacaoQuemSomosTimelineAno
          comunicacaoQuemSomosTimelineDescricao
          comunicacaoQuemSomosTimelineTitulo
          comunicacaoQuemSomosTimelineImagem {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    allWpEquipe {
      nodes {
        id
        equipeFields {
          comunicacaoEquipeCargo
          comunicacaoEquipeLinkedin
          comunicacaoEquipeNome
          comunicacaoEquipeFoto {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
