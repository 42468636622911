import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PersonImage({ imageSrc, linkedIn}){

  if(!imageSrc) imageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image" }
  const image = getImage(imageSrc.localFile)

  return (
    <div className="p-r mb-3 p-3 tac">
      <GatsbyImage image={image} className="image-cropper overlap-brd-purple" imgClassName="profile-pic img-fluid" objectFit="cover" alt={imageSrc.altText} />
    </div>
  )
}